<template>
  <!-- 引入样式 -->
  <div class="sortProduct">
    <NavHeader />

    <div class="content_container">

      <div class="box">

        <el-breadcrumb separator-class="el-icon-arrow-right ">
          <el-breadcrumb-item>
            <span
              v-if='str=="4sShop"'
              @click="$router.push('/flagShip')"
            >4s旗舰店 </span>
            <span
              v-else
              @click="$router.push('/brands')"
            >品牌供应商 </span>
          </el-breadcrumb-item>

          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>

      </div>
      <!-- 
      <div class="filtrate">
        <p @click="getProduct(0)">综合</p>
        <p @click="getProduct(1)">销量<i :class="{'el-icon-top': searchData.sale_num_pr=='asc','el-icon-bottom': searchData.sale_num_pr=='desc'}"></i></p>
        <p @click="getProduct(2)">价格<i :class="{'el-icon-top': searchData.price_pr=='asc','el-icon-bottom': searchData.price_pr=='desc'}"></i></p>
        <div
          class="sorter"
          style="float: right"
        ></div>
      </div> -->
      <div class="shop-list">

        <ul>
          <li
            class="item"
            v-for="(item, index) in shopList"
            :key="index"
          >
            <div class="card">

              <div class="tips"><span v-if='item.shop_type==2'>品牌供应商</span><span v-if="item.shop_type==3">4s旗舰店</span></div>
              <img
                v-if='item.shop_logo'
                :src=" item.shop_logo"
              />
              <img
                v-else
                src="../../assets/images/tx.jpg"
                alt=""
              />
              <div class="r">
                <p>{{item.shop_name}}</p>
                <p>{{item.shop_scope}}</p>
                <button
                  class="btn"
                  @click="$util.toShop(item.shop_type, item.shop_id )"
                >

                  进入店铺</button>
              </div>
            </div>
            <dl
              v-if='!item.get_goods.length'
              style='height:160px;'
            >
              <el-empty
                image-size='110'
                style='padding:0'
                description="暂无商品"
              ></el-empty>
            </dl>
            <dl v-else>
              <dd
                v-for='(o,i) in item.get_goods'
                :key="i"
              >
                <img
                  :src='$config.baseUrl+o.good_url'
                  @click="$util.toDetail(o.shop_type, o.goods_id )"
                >
                <p>{{o.goods_name}}</p>

              </dd>
            </dl>
          </li>
        </ul>
      </div>
      <el-pagination
        @current-change="currentChange"
        :current-page.sync="searchData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="searchData.limit"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  components: {
    NavHeader
  },
  data() {
    return {
      str: "",
      listDataSort: [],
      brand: undefined,
      cindex: undefined,
      cindex1: undefined,
      cindex2: undefined,
      idx: undefined,
      sort: { id: "", name: "" },

      listDataBrand: [],
      searchData: {
        brand_id: "",
        catid: "",
        shop_type: 3,
        page: 1,
        limit: 10
      },
      total: 0,

      isErr: false,
      timer: null,

      car: false,
      num: "0",
      listIndex: 0,
      name: "",
      shopList: [],
      saleList: [],
      priceList: [],
      Category: [],
      children: [],
      child: [],
      navList: [
        {
          name: "全部"
        },
        {
          name: "销量"
        },
        {
          name: "价格"
        }
      ]
    };
  },

  computed: {},
  mounted() {
    this.str = this.$route.query.str;
    console.log("$route.query.str", this.$route.query.str);
    // this.searchData = Object.assign(this.searchData, this.$route.query);
    // if (this.$route.query.goodsname) {
    //   this.$store.commit("setHistorySeach", this.searchData.goodsname);
    // }
    this.currentChange();

    // if (this.$route.query.catid) {
    //   this.getBrand(this.$route.query.catid);
    // }

    // this.sort.id = this.$route.query.catid;
    // this.sort.name = this.$route.query.sort;
    //产品分类列表
  },
  methods: {
    getProduct(index) {
      if (index == 0) {
        this.searchData.price_pr = undefined;
        this.searchData.sale_num_pr = undefined;
      }
      if (index == 1) {
        this.searchData.price_pr = undefined;
        this.searchData.sale_num_pr =
          this.searchData.sale_num_pr == "desc" ? "asc" : "desc";
      }
      if (index == 2) {
        this.searchData.sale_num_pr = undefined;
        this.searchData.price_pr =
          this.searchData.price_pr == "asc" ? "desc" : "asc";
      }

      this.currentChange();
    },

    currentChange() {
      if (this.str == "brandShop") {
        this.searchData.shop_type = 2;
      }
      this.$get("/home/shop", this.searchData).then(res => {
        console.log("res.items.data", res.items.data);

        res.items.data.forEach(item => {
          item.get_goods = item.get_goods.filter((o, i) => {
            return i < 5;
          });
        });
        this.shopList = res.items.data;
        this.total = res.items.total;
      });
    }
  },
  created() {},

  watch: {
    "$route.query"() {
      this.searchData = {};
      this.searchData = Object.assign(this.searchData, this.$route.query);
      this.currentChange();
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.sortProduct {
  background: #fff;
}
::v-deep .el-collapse-item__header {
  display: inline-block;
}
::v-deep .el-collapse {
  display: inline-block;
  float: left;
}
.box {
  background: #fff;
  padding: 10px;
  margin-bottom: 5px;
}

.dropdown {
  p {
    font-weight: bold;
  }
  padding: 20px;
  dl {
    margin-bottom: 10px;
  }
  dd {
    color: #888;
    display: inline-block;
    margin: 5px 10px;
  }
  dd:hover {
    color: red;
    cursor: pointer;
  }
  ul {
    li {
      display: inline-block;
    }
  }
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.red {
  color: red !important;
}
.name {
}
.hover {
  cursor: pointer;
}
.listBrand li {
  display: inline-block;
  margin: 5px;
  margin-top: 0;
  color: #888;
}
.listBrand li:hover {
  color: red;
  cursor: pointer;
}

.filtrate {
  margin: 10px 0;
  padding: 8px 5px 0 5px;
  position: relative;
  z-index: 10;
  background: #faf9f9;
  color: #806f66;
  line-height: 20px;

  p {
    margin-right: 10px;
    text-align: center;
    display: inline-block;
    margin-left: -1px;
    overflow: hidden;
    padding: 0 5px 0 5px;
    cursor: pointer;
    height: 22px;
    line-height: 20px;
    border: 1px solid #ccc;

    i {
      font-weight: 800;
    }
  }
}

.pointer {
  background: #fe6925;

  color: #fff;
  border-radius: 5px;
}
.topbar {
  background: #fff;
  width: 100%;
  text-align: center;
  color: #757575;
  border-bottom: #ccc 1px solid;

  height: 50px;
  line-height: 50px;

  ul {
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    @include row;
    li {
      cursor: pointer;

      @include center;
      @include row;
      flex-wrap: nowrap;
      height: 30px;
      line-height: 30px;
      width: 80px;
      text-align: center;
      margin: 0 20px;
      @include center;
    }
  }
}
.Detail {
  background: #f5f5f5;
}
.shop-list {
  width: 100%;
  background: #fff;

  ul {
    width: 100%;
    box-sizing: border-box;
    li {
      // background: #f0f0f0;
      border: 1px solid #f0f0f0;
      margin-top: 10px;
      box-sizing: border-box;

      @include space-between;
      width: 100%;
      padding: 20px;
      .card {
        @include space-between;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        border: 1px solid #f0f0f0;
        width: 390px;
        height: 150px;
        background: url("../../assets/images/mp.jpg") no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        position: relative;
        .tips {
          position: absolute;
          right: 10px;
          top: 10px;
          border: 1px solid #f8be7c;
          // background: rgba($color: #f2952b, $alpha: 0.3);
          border-radius: 10px;
          color: #e94c15;
          padding: 2px 5px;
        }
        img {
          margin-right: 10px;
          width: 130px;
          height: 50px;
        }
        .r {
          flex: 1;
          p:nth-child(1) {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 10px;
          }
          p {
            color: #999;
          }
          .btn {
            width: 120px;
            text-align: center;
            color: #fff;
            height: 35px;
            line-height: 35px;

            border-radius: 20px;
            border: 0;
            background-color: #f32f01; /* 浏览器不支持时显示 */
            background-image: linear-gradient(#f32f01, #f39801);
            margin-top: 10px;
          }
        }
      }

      dl {
        flex: 1;
        margin: 0 -5px;

        dd {
          box-sizing: border-box;
          background: #fff;
          padding: 10px;
          float: left;
          width: 160px;
          margin: 0 5px;
          height: 160px;
          word-break: break-all;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          img {
                     width: 160px;

            height: 160px;
          }
        }
      }
      dl:after {
        content: "";
        width: 30%;
      }
    }
  }
}
.banner {
  width: 100%;
  img {
    width: 100%;
    height: 400px;
  }
}
.product-info {
  background: #fff;
  padding: 20px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px;
}
</style>
 